<template>
  <div class="page">
    <div class="nav">
      <header-base :sear="'hide'"></header-base>
    </div>

    <div class="bodycontent">
      <div class="detail_title">
        <p class="detail_title_p">
          {{$t('m.personalcenter_myorder.order_status')}}：
          <span class="title_p_span">{{
            status == 0
                ? "待付款"
                : status == 1
                ? "已付款"
                : status == 5
                ? "待发货"
                : status == 2
                ? "卖家待备货"
                : status == 3
                ? "待确认备货"
                : status == 12
                ? "待付尾款"
                : status == 8
                ? "待确认发票"
                : status == 4
                ? "拒绝确认备货"
                : status == 6
                ? "待收货"
                : status == 7
                ? "已收货"
                : status == 9
                ? "已完成"
                : status == 10
                ? "已取消"
                : status == 11
                ? "订单失败"
                : status == 15
                ? "预订成功"
                : status == 16
                ? "预订失败"
                : status == 17
                ? "待确认"
                : status == 30
                ? "待付款-预提"
                : status == 31
                ? "待付款-待预提"
                : status == 32
                ? "已付款-待预提"
                : status == 33
                ? "卖家待确认预提"
                : status == 34
                ? "待确认预提"
                : status == 35
                ? "待重新预提"
                : status == 40
                ? "转售中"
                : ""}}</span>
        </p>
        <span class="detail_title_span">
          <button class="detail_title_button">{{$t('m.personalcenter_myorder.Contact_seller')}}</button>
        </span>
      </div>

      <div class="detail_msg" v-if="orderType!=11">
        <div class="msg_left">
          <!-- 待收货、已完成 -->
          <div class="pay_div" v-if="status == 6 || status == 9">
            <div class="wuliu_title">
              <h3>{{$t('m.personalcenter_myorder.logistics_information')}}</h3>
              <div class="row_left">
                <span class="msg_title">发货方式：</span>
                <span class="msg_cont">{{ omsOrder.takeGoodsType==1?'物流':'自提' }}</span>
              </div>
              <div v-show="omsOrder.takeGoodsType!==0">
                <div class="row_left">
                  <span class="msg_title">物流公司：</span>
                  <span class="msg_cont">{{ omsOrder.deliveryCompany }}</span>
                </div>
                <div class="row_left">
                  <span class="msg_title">运单号码：</span>
                  <span class="msg_cont">{{ omsOrder.deliverySn}}</span>
                </div>
              </div>
            </div>
            <div class="wuliu_body" v-show="omsOrder.takeGoodsType!==0">
              <h3>物流跟踪</h3>
              <el-timeline>
                <el-timeline-item
                  v-for="(item, index) in activities"
                  :key="index"
                  :color="item.color"
                  :hollow="item.hollow"
                  :timestamp="item.timestamp"
                >
                  {{ item.content }}
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>

          <!-- 已付款待预提 -->
          <div class="pay_div" v-if="status == 32 || status == 35">
            <div class="wenzi">
              <p class="pay_div_p1">商品预提时间</p>
              <p class="pay_div_p1">预提开始时间：<span>{{preTakeTimeSco.start}}</span></p>
              <p class="pay_div_p1">预提结束时间：<span>{{preTakeTimeSco.end}}</span></p>
              <p class="pay_div_p2">请在规定时间进行预提</p>
            </div>
            <!-- 功能按钮 -->
            <div class="pay_div_btn">
              <button class="pay_btn1" @click="notarizeWithholding">申请预提</button>
              <!-- <button class="pay_btn1">修改地址</button> -->
            </div>
          </div>

          <!-- 待确认预提 -->
          <div class="pay_div" v-if="status == 34">
            <div class="wenzi">
              <p class="pay_div_p1">待支付尾款<span>{{currencySymbol+omsOrder.finalAmount}}</span></p>
              <p class="pay_div_p2">请确认信息，费用
                <span>
                  <el-tooltip
                    placement="bottom-end" effect="light">
                    <template #content>
                      <div>
                          <span style="color:#999999;line-height:20px;">
                            尾款：{{currencySymbol+omsOrderInfo.prePayFee}}<br/>
                            预提费用：{{currencySymbol+omsOrderInfo.packFee}}
                          </span>
                      </div>
                      </template>
                      <span class="more">明细</span>
                    </el-tooltip>
                  </span>
                </p>
            </div>
            <!-- 功能按钮 -->
            <div class="pay_div_btn">
              <button class="pay_btn2" @click="payment()">确认预提</button>
            </div>
          </div>

          <!-- 待确认备货 -->
          <div class="pay_div" v-if="status == 3">
            <div class="wenzi">
              <p class="pay_div_p1">待支付备货费用<span>{{currencySymbol+(omsOrder.finalAmount)}}</span></p>
              <p class="pay_div_p2">请确认备货信息，备货费用
                <span>
                  <el-tooltip
                    placement="bottom-end" effect="light">
                    <template #content>
                      <div>
                          <p style="color:#999999;line-height:20px;"
                          v-for="(i,k) in this.amountList" :key="k"
                          v-show="status == 3 && i.name=='预提费用'?false:true">
                            {{i.name}}{{currencySymbol+i.productId}}
                          </p>
                          <!-- <span style="color:#999999;line-height:20px;">
                            差价：{{currencySymbol+omsOrderInfo.diffPrice}}
                          </span> -->
                      </div>
                      </template>
                      <span class="more">明细</span>
                    </el-tooltip>
                  </span>
                </p>
            </div>
            <!-- 功能按钮 -->
            <div class="pay_div_btn">
              <button class="pay_btn2" @click="ConfirmGoods">确认备货</button>
              <button class="pay_btn1">修改地址</button>
            </div>
          </div>

          <!-- 待付款 -->
          <div class="pay_div" v-if="status == 0">
            <!-- 倒计时 -->
            <div class="wenzi">
              <p class="pay_div_p1">请在<span>24小时</span>内付款</p>
              <p class="pay_div_p2">若未及时付款，系统将自动取消订单</p>
            </div>
            <!-- 功能按钮 -->
            <div class="pay_div_btn">
              <button class="pay_btn1">修改地址</button>
              <button class="pay_btn2"
              @click="payAmonutFun">付款</button>
            </div>
            <a>取消订单</a>
          </div>

        </div>
        <div class="msg_right">
          <h3>{{$t('m.personalcenter_myorder.order_information')}}</h3>
          <div class="show_msg">
            <div class="right_row1 rowdiv">
              <div class="row_left">
                <span class="msg_title">{{$t('m.personalcenter_myorder.order_detail_number')}}：</span>
                <span class="msg_cont">{{ orderSn }}</span>
              </div>
              <div class="row_right">
                <span class="msg_title">{{$t('m.personalcenter_myorder.Delivery_date')}}：</span>
                <span class="msg_cont">{{omsOrder.takeGoodsTime}}</span>
              </div>
            </div>
            <div class="right_row2 rowdiv">
              <div class="row_left" v-if="omsOrder.takeGoodsType==1">
                <span class="msg_title">收货人姓名：</span>
                <span class="msg_cont">{{ omsOrder.receiverName }}</span>
              </div>
              <div class="row_right">
                <span class="msg_title">{{$t('m.personalcenter_myorder.creation_time')}}：</span>
                <span class="msg_cont">{{ omsOrder.createTime }}</span>
              </div>
            </div>
            <div class="right_row3 rowdiv">
              <div class="row_left" v-if="omsOrder.takeGoodsType==1">
                <span class="msg_title">{{$t('m.personalcenter_myorder.phone_number')}}：</span>
                <span class="msg_cont">{{ omsOrder.receiverPhone }}</span>
              </div>
              <div class="row_right">
                <span class="msg_title">预提方式：</span>
                <span class="msg_cont">{{
                  omsOrder.takeGoodsType == 0
                    ? "自提："
                    : omsOrder.takeGoodsType == 1
                    ? "物流"
                    : ""
                }}{{ omsOrder.takeGoodsType == 0?takeAddrVOS.name:''}}</span>
              </div>
            </div>
            <div class="right_row4 rowdiv" v-if="status!=3">
              <div class="row_left">
                <span class="msg_title">付款时间：</span>
                <span class="msg_cont">
                  {{ omsOrder.paymentTime||omsOrder.deliveryTime}}

                  <el-tooltip v-if="status == 6"
                   placement="bottom-end" effect="light">
                   <template #content>
                     <div>
                        <!-- <span style="color:#999999;line-height:20px;">确认预提时间：</span>xx<br /> -->
                        <!-- <span style="color:#999999;line-height:20px;">确认备货时间：</span>xx<br /> -->
                        <span style="color:#999999;line-height:20px;">发货时间：</span>xx
                     </div>
                    </template>
                    <span class="more">更多</span>
                  </el-tooltip>
                </span>
              </div>
              <div class="row_right" v-if="omsOrder.resaleNum!=0">
                <span class="msg_title">已转数量：</span>
                <span class="msg_cont">{{omsOrder.resaleNum+pmsProduct.unit}}</span>
              </div>
            </div>
            <div class="right_row4 rowdiv" v-if="omsOrder.takeGoodsType==1">
              <div class="row_left">
                <span class="msg_title">收货地址：</span>
                <span class="msg_cont">{{ omsOrder.receiverDetailAddress }}</span>
              </div>
            </div>
            <div class="right_row4 rowdiv" v-if="status!=12&&status!=0&&status!=30&&status!=31&&status!=10">
              <div class="row_left">
                <span class="msg_title">合同\单据：</span>
                <span class="msg_cont" style="color: #C6824E;cursor: pointer;"
                v-if="omsOrderContract"
                 @click="tolook(omsOrderContract.path)"
                 >《{{omsOrderContract.title}}》</span>
                 <span class="msg_cont" v-if="omsOrder.waybillPicture"
                 style="color: #C6824E;cursor: pointer;"
                 @click="tolook(omsOrder.waybillPicture)"
                 >、《运单》、</span>
                 <span class="msg_cont" v-if="omsOrder.policyPicture"
                 style="color: #C6824E;cursor: pointer;"
                 @click="tolook(omsOrder.policyPicture)"
                 >《保单》、</span>
                 <span class="msg_cont" v-if="omsOrder.billOfLadingPicture"
                 style="color: #C6824E;cursor: pointer;"
                 @click="tolook(omsOrder.billOfLadingPicture)"
                 >《提单》</span>
              </div>
            </div>
            <div class="right_row5 rowdiv">
              <div class="row_left">
                <span class="msg_title">{{$t('m.personalcenter_myorder.leave_words')}}：</span>
                <span class="msg_cont">
                  <el-input
                    v-model="detailaddress"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    type="textarea"
                    placeholder=""
                  >
                  </el-input>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 易货页面—————————————————————————————————————————— -->
      <!-- 易货订单展示demo  大框数据内容  -->
      <div class="detail_msg_yh" v-if="false">
        <div class="msg_left">
          <!-- 待收货、已完成 -->
          <div class="pay_div">
            <div class="wuliu_title">
              <h3>现有商品详细信息</h3>
              <div class="row_left">
                <span class="msg_title">账户名称：</span>
                <span class="msg_cont">{{}}</span>
              </div>
              <div>
                <div class="row_left">
                  <span class="msg_title">联系电话：</span>
                  <span class="msg_cont">{{ omsOrder.deliveryCompany }}</span>
                </div>
                <div class="row_left">
                  <span class="msg_title">交收地：</span>
                  <span class="msg_cont">{{ omsOrder.deliverySn}}</span>
                </div>
                <div class="row_left">
                  <span class="msg_title">商品名称：</span>
                  <span class="msg_cont">{{ omsOrder.deliverySn}}</span>
                </div><div class="row_left">
                  <span class="msg_title">商品库存：</span>
                  <span class="msg_cont">{{ omsOrder.deliverySn}}</span>
                </div><div class="row_left">
                  <span class="msg_title">商品价格：</span>
                  <span class="msg_cont">{{ omsOrder.deliverySn}}</span>
                </div><div class="row_left">
                  <span class="msg_title">拟易商品：</span>
                  <span class="msg_cont">{{ omsOrder.deliverySn}}</span>
                </div><div class="row_left">
                  <span class="msg_title">物流补偿金：</span>
                  <span class="msg_cont">{{ omsOrder.deliverySn}}</span>
                </div><div class="row_left">
                  <span class="msg_title">装卸补偿金：</span>
                  <span class="msg_cont">{{ omsOrder.deliverySn}}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="msg_right">
          <h3>摘单商品详细信息</h3>
          <div class="show_msg">
            <div class="right_row1 rowdiv">
              <div class="row_left">
                <span class="msg_title">账户名称：</span>
                <span class="msg_cont">{{ orderSn }}</span>
              </div>
            </div>
            <div class="right_row2 rowdiv">
              <div class="row_left">
                <span class="msg_title">联系电话：</span>
                <span class="msg_cont">{{ omsOrder.receiverName }}</span>
              </div>
            </div>
            <div class="right_row3 rowdiv">
              <div class="row_left">
                <span class="msg_title">交货地：</span>
                <span class="msg_cont">{{ omsOrder.receiverPhone }}</span>
              </div>
            </div>
            <div class="right_row4 rowdiv">
              <div class="row_left">
                <span class="msg_title">商品名称：</span>
                <span class="msg_cont">{{ omsOrder.receiverDetailAddress }}</span>
              </div>
            </div>
            <div class="right_row4 rowdiv">
              <div class="row_left">
                <span class="msg_title">商品库存：</span>
                <span class="msg_cont">{{ omsOrder.receiverDetailAddress }}</span>
              </div>
            </div>
            <div class="right_row4 rowdiv">
              <div class="row_left">
                <span class="msg_title">商品价格：</span>
                <span class="msg_cont">{{ omsOrder.receiverDetailAddress }}</span>
              </div>
            </div>
            <div class="right_row4 rowdiv">
              <div class="row_left">
                <span class="msg_title">拟易商品：</span>
                <span class="msg_cont">{{ omsOrder.receiverDetailAddress }}</span>
              </div>
            </div>
            <div class="right_row4 rowdiv">
              <div class="row_left">
                <span class="msg_title">物流补偿金：</span>
                <span class="msg_cont">{{ omsOrder.receiverDetailAddress }}</span>
              </div>
            </div>
            <div class="right_row4 rowdiv">
              <div class="row_left">
                <span class="msg_title">装卸补偿金：</span>
                <span class="msg_cont">{{ omsOrder.receiverDetailAddress }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 易货订单展示 -->
      <div class="detail_msg_yh" v-if="orderType==11">
        <div class="msg_left">
          <!-- 待收货、已完成 -->
          <div class="pay_div">
            <div class="wuliu_title">
              <h3>现有商品详细信息</h3>
              <div class="row_left">
                <span class="msg_title">收货人姓名：</span>
                <span class="msg_cont">{{ yhData.receiverName }}</span>
              </div>
              <div>
                <div class="row_left">
                  <span class="msg_title">手机号：</span>
                  <span class="msg_cont">{{ yhData.receiverPhone }}</span>
                </div>
                <div class="row_left">
                  <span class="msg_title">收货地址：</span>
                  <span class="msg_cont">{{ yhData.receiverDetailAddress}}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="msg_right">
          <h3>订单信息</h3>
          <div class="show_msg">
            <div class="right_row1 rowdiv">
              <div class="row_left">
                <span class="msg_title">订单编号：</span>
                <span class="msg_cont">{{ orderSn }}</span>
              </div>
            </div>
            <div class="right_row2 rowdiv">
              <div class="row_left">
                <span class="msg_title">创建时间：</span>
                <span class="msg_cont">{{ yhData.createTime }}</span>
              </div>
            </div>
            <div class="right_row5 rowdiv">
              <div class="row_left">
                <span class="msg_title">留言：</span>
                <span class="msg_cont">
                  <el-input
                    v-model="detailaddress"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    type="textarea"
                    placeholder=""
                  >
                  </el-input>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="datail_order datail_order_yh" v-if="orderType==11">
        <p class="datail_order_p">
          易货商品信息
        </p>
        <div class="datail_order_table">
          <table class="order_table">
            <tr class="order_table_title">
              <th v-for="i in orderTableTitle" :key="i"
              >
                {{i}}
              </th>
            </tr>
            <tr>
              <ul class="order_body_ul">
                <li class="order_body_li body_num1">
                  <div class="namebody">
                    <img :src="yhData.exchangeProductPic" alt="">
                    <div class="name_time">
                      <p>{{yhData.exchangeProductName}}</p>
                    </div>
                  </div>
                </li>
                <li class="order_body_li body_num2">
                  <span class="li2_span_kg">{{currencySymbol+yhData.price || ''}}</span>
                </li>
                <li class="order_body_li body_num3">
                  <p class="name_phone_p">{{
                    yhData.sellQuantity+'吨'
                    }}</p>
                </li>
                <li class="order_body_li body_num4">
                  <span class="money_span">{{yhData.exchangeProductName}}</span>
                </li>
                <li class="order_body_li body_num5">
                  <span class="money_span">{{currencySymbol+yhData.freightAmount}}</span>
                </li>
                <li class="order_body_li body_num6" v-if="status!==32">
                  <span>{{currencySymbol+yhData.carryMoney}}</span>
                </li>
                <li class="order_body_li body_num7">
                  <span>{{currencySymbol+
                    (yhData.price*yhData.sellQuantity)}}</span>
                </li>
              </ul>
            </tr>
          </table>
        </div>
      </div>
      <!-- 易货页面—————————————————————————————————————————— -->

      <div class="datail_seller" v-if="orderType!=11">
        <p class="datail_seller_p">
          {{$t('m.personalcenter_myorder.Seller_information')}}
        </p>
        <div class="datail_seller_constent">
          <p>{{$t('m.personalcenter_myorder.seller_name')}}：<span>{{ companyName }}</span></p>
          <p>{{$t('m.personalcenter_myorder.phone')}}：<span>{{ companyPhoneNumber }}</span></p>
          <p v-if="omsOrder.stockUpAmount!=0">备货数量：<span>{{ omsOrder.stockUpAmount+pmsProduct.unit }}</span></p>
        </div>
      </div>
      <div class="datail_seller" v-if="orderType!=11">
        <p class="datail_seller_p">
          {{$t('m.personalcenter_myorder.invoice_information')}}
        </p>
        <div class="datail_seller_constent" v-if="umsMemberBill.billType == 0">
          <p>发票抬头：<span>{{ umsMemberBill.billHeader }}</span></p>
          <p>纳税识别号：<span>{{ umsMemberBill.billCode }}</span></p>
          <p>收票人：<span>{{ umsMemberBill.billReceiverName }}</span></p>
          <p>收票电话：<span>{{ umsMemberBill.billReceiverPhone }}</span></p>
          <p>收票地址：<span>{{ umsMemberBill.billReceiverAddress }}</span></p>
        </div>
        <div class="datail_seller_constent" v-else>
          <p>发票抬头：<span>{{ umsMemberBill.billHeader }}</span></p>
          <p>纳税识别号：<span>{{ umsMemberBill.billCode }}</span></p>
          <p>收票人：<span>{{ umsMemberBill.billReceiverName }}</span></p>
          <p>收票电话：<span>{{ umsMemberBill.billReceiverPhone }}</span></p>
          <p>收票地址：<span>{{ umsMemberBill.billReceiverAddress }}</span></p>
          <p>开户银行：<span>{{ umsMemberBill.billBank }}</span></p>
          <p>开户账号：<span>{{ umsMemberBill.billBankAccount }}</span></p>
          <p>开户手机号：<span>{{ umsMemberBill.billPhone }}</span></p>
          <p>经营地址：<span>{{ umsMemberBill.billAddress }}</span></p>
        </div>
      </div>
      <div class="datail_order" v-if="orderType!=11">
        <p class="datail_order_p">
          {{$t('m.personalcenter_myorder.commodity_information')}}
          <el-tag v-if="orderType==10" size="large" effect="dark" type="danger">样品订单</el-tag>
        </p>
        <div class="datail_order_table">
          <table class="order_table">
            <tr class="order_table_title">
              <th v-for="i in orderTableTitle" :key="i"
              >
                {{i}}
              </th>
            </tr>
            <tr>
              <ul class="order_body_ul">
                <li class="order_body_li body_num1">
                  <div class="namebody">
                    <img :src="omsOrderItem.productPic" alt="">
                    <div class="name_time">
                      <p>{{omsOrderItem.productName}}</p>
                    </div>
                  </div>
                </li>
                <li class="order_body_li body_num2">
                  <span class="li2_span_kg">{{productAttr || ''}}</span>
                </li>
                <li class="order_body_li body_num3">
                  <p class="name_phone_p">{{
                    currencySymbol+omsOrderItem.productPrice+'/'+pmsProduct.unit
                    }}</p>
                </li>
                <li class="order_body_li body_num4">
                  <span class="money_span">{{omsOrderItem.productQuantity+pmsProduct.unit}}</span>
                </li>
                <li class="order_body_li body_num5" v-if="status!==32">
                  <span>{{
                  omsOrder.takeGoodsType == 0
                    ? "自提:"
                    : omsOrder.takeGoodsType == 1
                    ? "物流"
                    : ""
                }}</span>
                </li>
                <li class="order_body_li body_num6"
                v-if="status==30||status==32||orderType==0||orderType==10?false:true">
                  <span v-if="omsOrderInfo">{{currencySymbol+
                    (omsOrderInfo.packFee?omsOrderInfo.packFee:0)}}</span>
                </li>
              </ul>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="bodyfoot" v-if="status!=6&&status!=3 && orderType!=11">
      <p>{{$t('m.personalcenter_myorder.commodity_price')}}：<span>{{currencySymbol+(omsOrderItem.productPrice*omsOrderItem.productQuantity)}}</span></p>
      <p>{{$t('m.personalcenter_myorder.accrued_expenses')}}：<span>{{currencySymbol+(omsOrderInfo!=null&&omsOrderInfo.packFee?omsOrderInfo.packFee:0)}}</span></p>
      <p>{{$t('m.personalcenter_myorder.logistics_fees')}}：<span>{{currencySymbol+wuliuMoney}}</span></p>
      <p>备货差价：<span>{{currencySymbol+beihuoTestMoney}}</span></p>
      <p>总货款：<span>{{currencySymbol+omsOrder.totalAmount}}</span></p>
      <p>{{$t('m.personalcenter_myorder.actual_payment')}}：<span>{{currencySymbol+omsOrder.payAmount}}</span></p>
    </div>

    <!-- 待确认备货 -->
    <div class="bodyfoot" v-if="status == 3">
      <p>{{$t('m.personalcenter_myorder.commodity_price')}}：<span>{{currencySymbol+(omsOrderItem.productPrice*omsOrderItem.productQuantity)}}</span></p>
      <p>{{$t('m.personalcenter_myorder.accrued_expenses')}}：<span>{{currencySymbol+omsOrderInfo.packFee}}</span></p>
      <p>{{$t('m.personalcenter_myorder.logistics_fees')}}：<span>{{currencySymbol+(wuliuMoney || omsOrder.freightAmount)}}</span></p>
      <p>{{$t('m.personalcenter_myorder.Stock_up_expenses')}}：<span>
        {{currencySymbol+omsOrder.finalAmount}}
        {{omsOrder.finalAmount>=0?'(待支付)':'(待退款)'}}
      </span></p>
      <el-divider border-style="dotted"></el-divider>
      <p class="ft">{{$t('m.personalcenter_myorder.Total_amount')}}：<span>{{currencySymbol+omsOrder.totalAmount}}</span></p>
      <!-- <p class="ft">加工费：<span>{{currencySymbol+omsOrderInfo.packFee}}</span></p> -->
    </div>

    <!-- 待收货 -->
    <div class="bodyfoot" v-if="status == 6 && orderType!=11">
      <p>{{$t('m.personalcenter_myorder.commodity_price')}}：<span>{{currencySymbol+(omsOrderItem.productPrice*omsOrderItem.productQuantity)}}</span></p>
      <p v-if="orderType!=0&&orderType!=10">{{$t('m.personalcenter_myorder.accrued_expenses')}}：<span>{{currencySymbol+omsOrderInfo.packFee}}</span></p>
      <p>{{$t('m.personalcenter_myorder.logistics_fees')}}：<span>{{currencySymbol+(wuliuMoney || omsOrder.freightAmount)}}</span></p>
      <!-- <p>优惠券：<span>{{'-'+currencySymbol+'0'}}</span></p> -->
      <p v-if="orderType!=0&&orderType!=10">{{$t('m.personalcenter_myorder.Stock_up_expenses')}}：<span>{{currencySymbol+omsOrderInfo.diffPrice}}</span></p>
      <el-divider border-style="dotted"></el-divider>
      <p class="ft">{{$t('m.personalcenter_myorder.Total_amount')}}：<span>{{currencySymbol+omsOrder.totalAmount}}</span></p>
    </div>
    <!-- 待收货 -->
    <div class="bfoot" v-if="status == 6 && orderType!=11">
      <p>{{$t('m.personalcenter_myorder.actual_payment')}}：<span>{{currencySymbol+omsOrder.payAmount}}</span></p>
    </div>

     <!-- 查看预提申请 -->
    <el-dialog
      title="申请预提信息"
      v-model="showYt"
      width="50%"
    >
      <div>
        <el-card>
          <div class="textItem">
            <span>预提方式：</span>
            <el-select v-model="preTakeWayValue" size="mini" placeholder="选择预提方式">
              <el-option
                v-for="item in preTakeWay"
                :key="item.id"
                :label="item.spec+currencySymbol+item.price"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-card>
        <el-card>
          <div class="address">
            <div class="textItem">配送方式</div>
            <div class="textItemVal">
              <el-radio-group v-model="isEnter" @change="radioChange">
                <el-radio :label="1">物流</el-radio>
                <el-radio :label="0">自提</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="textItem" v-if="isEnter==1">
            <!-- <span>收货地址：</span> -->
            <!-- <el-select v-model="areaValue" size="mini" placeholder="选择收货地址">
              <el-option
                v-for="item in logisticsVos"
                :key="item.id"
                :label="item.detailAddress"
                :value="item.id"
              >
              </el-option>
            </el-select> -->
            <wu-liu></wu-liu>
          </div>
          <div class="textItem" v-if="isEnter==0">
            自提地点：{{ takeAddrVOS.name }}
          </div>
        </el-card>
        <div style="padding: 10px 0;">
          <el-button
            :disabled="advancedisabled"
            @click="subadvance"
            >提交</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 待确认备货 -->
    <el-dialog
      title="确认备货信息"
      v-model="showBh"
      width="50%"
    >
      <div>
         <el-table :data="bhData" style="width: 100%">
          <el-table-column prop="orderSn" label="商品型号" width="200"/>
          <el-table-column prop="stockUpAmount" label="备货数量" />
          <el-table-column prop="freightAmount" label="物流费" />
          <el-table-column prop="jiagong" label="备货差价" />
          <el-table-column prop="all" :label="omsOrder.finalAmount>=0?'应付金额':'应退金额'" />
        </el-table>
        <div style="padding: 10px 0">
          <el-button
            :disabled="advancedisabled"
            @click="subConfirmGoods"
            >提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>

  <div class="divfooter" v-if="orderType==11">
    <Footer/>
  </div>
</template>

<script>
import { ref } from 'vue-demi'
import http from '../../api/http'
import { ElMessage } from 'element-plus'
import { setStore } from '../../tools/storage'
import moment from 'moment'
import WuLiu from '../buy/WuLiu.vue'
import emitter from '../../tools/bus'
import Footer from '../index/Footer.vue'
import HeaderBase from '../../components/HeaderBase.vue'

// 易货订单详情
// const getExchangeOrderInfoByOrderId = (data) => {
//   return http.get(`/mall-order/order/getExchangeOrderInfoByOrderId/${data}`)
// }

export default {
  components: { WuLiu, Footer, HeaderBase },
  name: 'OrderDetail',
  data () {
    return {
      orderType: '', // 当前是什么订单// 订单类型0：正常，1：秒杀，2：转售，3：预购
      orderSn: '',
      orderTableTitle: [
        this.$t('m.personalcenter_myorder.detail_product_name'),
        this.$t('m.personalcenter_myorder.specification'),
        this.$t('m.personalcenter_myorder.unit_price'),
        this.$t('m.personalcenter_myorder.quantity'),
        this.$t('m.reservation_varietiy_details.Delivery_method'),
        this.$t('m.personalcenter_myorder.Accrued_expenses')
      ],
      takeAddrVOS: '',
      pmsBillHolding: '',
      currencySymbol: '', // 货币种类
      companyName: '', // 商家名字
      companyPhoneNumber: '', // 商家电话
      pmsProduct: '', // 商家信息
      omsOrder: '', // 订单信息
      detailaddress: '', // 留言
      omsOrderItem: '', // 商品信息 =>规格
      productAttr: '',
      omsOrderInfo: '', // 预提费
      omsOrderContract: '', // 合同信息
      preTakeTimeSco: {
        start: '',
        end: ''
      }, // 距离预提还剩几天
      preTakeWay: '', // 预提方式
      preTakeWayValue: '', // 已选预提方式
      areaValue: '', // 已选地址方式
      isEnter: 1, // 选择配送方式
      advancedisabled: false, // 提交预提按钮 是否禁用
      status: '',
      umsMemberBill: '', // 发票信息
      logisticsVos: '', // 买家地址信息
      takeProductAddressId: '',

      amountList: '', // 额外费用
      wuliuMoney: 0, // 物流费
      beihuoTestMoney: 0, // 备货差价

      showYt: false, // 预提弹窗
      showBh: false, // 确认备货弹窗
      bhData: '', // 备货信息

      // 易货订单
      yhData: '',

      activities: ref([
        {
          content: '已完成',
          timestamp: '2018-04-03 20:46',
          color: '#0bbd87',
          hollow: true
        },
        {
          content: '已发货',
          timestamp: '2018-04-03 20:46',
          color: '',
          hollow: true
        }
      ]) // 物流信息
    }
  },
  methods: {
    // 初始化订单详情
    async initData (orderSn) {
      const loading = this.$loading({
        lock: true,
        text: '请稍等...'
      })
      http.get(`/mall-order/order/getOrderInfoByOrderId/${orderSn}`)
        .then(res => {
          if (res.data.code !== 200) {
            ElMessage.error(res.data.msg)
          }
          const result = res.data.data
          console.log(result)
          if (this.$route.query.orderType === '11') {
            this.yhData = result
            this.orderTableTitle = ['商品名称', '单价', '数量', '拟易商品', '物流补偿金', '装卸补偿金', '总货款']
            console.log(this.yhData)
            this.status = result.status
            this.currencySymbol = result.currencySymbol || '￥'
            loading.close()
            return
          }
          this.orderType = result.omsOrder.orderType
          this.takeAddrVOS = res.data.data.takeAddrVOS
          this.pmsBillHolding = res.data.data.pmsBillHolding
          this.currencySymbol = result.pmsProduct.currencySymbol || '' // 货币种类
          this.companyName = result.companyName // 商家名字
          this.companyPhoneNumber = result.companyPhoneNumber // 商家电话
          this.pmsProduct = result.pmsProduct // 商家信息
          this.omsOrder = result.omsOrder // 订单信息
          if (result.omsOrderContract !== null && result.omsOrderContract.length !== 0) {
            this.omsOrderContract = result.omsOrderContract[0] // 合同信息
          }
          this.detailaddress = result.omsOrder.note // 留言
          this.omsOrderItem = result.omsOrderItem // 商品信息
          this.omsOrderInfo = result.omsOrderInfo
          this.status = this.omsOrder.status
          this.umsMemberBill = result.umsMemberBill
          this.logisticsVos = result.umsMemberReceiveAddressList
          // this.takeProductAddressId = result.umsMemberReceiveAddressList[0].id

          if (res.data.data.omsOrder.orderType === 8) {
            this.paymoney = result.omsOrder.prepayAmount
          } else {
            this.paymoney = result.omsOrder.payAmount
          }
          if (this.status === 32 || this.status === 35) {
            this.preTakeTimeSco.start = this.omsOrder.presellTimeScope.split(',')[0].split('：')[1]
            this.preTakeTimeSco.end = this.omsOrder.presellTimeScope.split(',')[1].split('：')[1]
          }

          this.amountList = result.amountList // 判断费用明细
          this.omsOrderItem.productAttr = JSON.parse(this.omsOrderItem.productAttr)

          if (this.status === 0 || this.status === 32) {
            // this.productAttr = this.omsOrderItem.productAttr[1].value
            this.productAttr = this.omsOrderItem.productAttr[1].value
          } else {
            // this.productAttr = this.omsOrderItem.productAttr[0].value
            this.productAttr = this.omsOrderItem.productAttr[1].value || this.omsOrderItem.productAttr[0].value
          }

          for (const i of this.amountList) {
            // console.log(i)
            if (i.name === '物流费') {
              this.wuliuMoney = i.productId
              continue
            } else if (i.name === '备货差价') {
              this.beihuoTestMoney = i.productId
              continue
            } else if (i.name === '预提费用') {
              continue
            } else {
              this.wuliuMoney = 0
              this.beihuoTestMoney = 0
            }
          }
          // 判断是不是现货 orderType==0 是现货 orderType==10 是样品现货
          if (this.omsOrder.orderType !== 0 && this.omsOrder.orderType !== 10) {
            this.omsOrder.takeGoodsTime = this.omsOrder.takeGoodsTime.substring(0, 10)
          } else {
            this.orderTableTitle = ['商品名称', '规格', '单价', '数量', '提货方式']
            this.omsOrder.takeGoodsTime = ''
            // this.wuliuMoney = this.omsOrder.freightAmount // 现货物流费
          }

          // if (this.amountList.length > 0) {
          //   if (this.amountList[0].name === '物流费') {
          //     this.wuliuMoney = this.amountList[0].productId ? this.amountList[0].productId : 0
          //   } else {
          //     this.wuliuMoney = 0
          //   }
          // } else {
          //   this.wuliuMoney = 0
          // }

          if (result.preTakeWayEntities) {
            this.preTakeWay = result.preTakeWayEntities
          }

          if (this.omsOrder.status === 0) {
            this.omsOrder.prepayAmount
              ? (this.paymoney = this.omsOrder.prepayAmount)
              : (this.paymoney = this.omsOrder.payAmount)
          }
          if (this.omsOrder.status === 12) {
            this.paymoney = this.omsOrder.finalAmount
          }
          if (this.omsOrder.status === 30) {
            this.orderTableTitle = ['商品名称', '规格', '单价', '数量', '预提方式']
          }
          if (this.omsOrder.status === 32) {
            this.orderTableTitle = ['商品名称', '规格', '单价', '数量']
          }
          loading.close()
        }).catch(err => {
          console.log(err)
        })
    },
    payAmonutFun () { // 付款方法
      this.$router.push(
        {
          path: '/buy/settleaccounts',
          query: {
            orderSn: encodeURIComponent(this.orderSn.toString()),
            payAmount: encodeURIComponent(JSON.stringify(this.currencySymbol + this.omsOrder.totalAmount))
          }
        }
      )
    },
    notarizeWithholding () { // 确认预提弹出
      this.showYt = true
    },
    // 提交确认预提
    subadvance () {
      if (this.isEnter === 1) {
        if (!this.areaValue) {
          ElMessage({
            message: '请选择收货地址',
            type: 'error'
          })
          return
        }
        if (!this.preTakeWayValue) {
          ElMessage({
            message: '请选择预提方式',
            type: 'error'
          })
          return
        }
      } else {
        if (!this.preTakeWayValue) {
          ElMessage({
            message: '请选择预提方式',
            type: 'error'
          })
          return
        }
      }
      this.advancedisabled = true
      const loading = this.$loading({
        lock: true,
        text: '提交中'
      })
      let postdata = {}
      if (this.isEnter === 1) {
        postdata = {
          preWayId: this.preTakeWayValue,
          orderSn: this.omsOrder.orderSn,
          takeGoodsType: this.isEnter,
          // takeGoodsAddrId: this.areaValue,
          receiverAddressId: this.areaValue // 收货地址id
        }
      } else {
        postdata = {
          preWayId: this.preTakeWayValue,
          orderSn: this.omsOrder.orderSn,
          takeGoodsType: this.isEnter,
          takeGoodsAddrId: 1 // 提货地址id
          // receiverAddressId: this.areaValue // 地址id
        }
      }
      console.log(postdata)
      http.post('/mall-order/order/appWithlolding', postdata)
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            console.log(res)
            loading.close()
            this.showYt = false
            ElMessage({
              message: '提交成功',
              type: 'success'
            })
            // this.orderdetails()
          } else {
            this.advancedisabled = false
            loading.close()
            ElMessage.error(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
          loading.close()
        })

      // TODO handle the exception
    },
    payment () { // 去付款
      if (this.status === 34) { // 确定预提支付
        setStore('WithAccTime', moment(new Date()).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'))
        this.$router.push(
          {
            path: '/buy/withholdingaccount',
            query: {
              orderSn: encodeURIComponent(this.orderSn.toString()),
              payAmount: encodeURIComponent(JSON.stringify(
                this.pmsProduct.currencySymbol + this.omsOrder.finalAmount))
            }
          }
        )
      }
    },
    ConfirmGoods () { // 确认备货弹出
      this.bhData = [{
        orderSn: this.orderSn,
        stockUpAmount: this.omsOrder.stockUpAmount, // 备货数量
        freightAmount: this.currencySymbol + (this.wuliuMoney || this.omsOrder.freightAmount), // 物流费
        jiagong: this.currencySymbol + this.beihuoTestMoney, // 备货差价
        all: this.currencySymbol + this.omsOrder.finalAmount // 应付金额/应退金额
      }]
      this.showBh = true
    },
    // 提交确认备货
    subConfirmGoods () {
      // setStore('stockUpAccTime', moment(new Date()).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'))
      // console.log(this.bhData)
      this.$router.push(
        {
          path: '/buy/stockupaccounts',
          query: {
            orderSn: encodeURIComponent(this.orderSn.toString()),
            payAmount: encodeURIComponent(JSON.stringify(this.bhData[0].all))
          }
        }
      )
    },

    radioChange (e) { // 切换选项
      this.isEnter = e
    },
    tolook (url) {
      if (!url) {
        // console.log(url);
        ElMessage.warning({
          message: '还未签署'
        })
        return
      }
      window.open(url)
    }
  },
  created () {
    this.orderSn = this.$route.query.orderSn
    this.orderType = this.$route.query.orderType
    this.initData(this.orderSn)
    emitter.on('wuliumesArea', (data) => {
      // console.log(data)
      this.areaValue = data
    })
  },
  mounted () {
  },
  setup () {
    return {

    }
  }
}
</script>

<style  lang="scss" scoped>
@import '../../style/viriables.scss';

.page{
  width: 100%;
  background: $allbgColor;
  // height:98vh;
  min-height: 98vh;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 20px;
  .nav{
    // height: 30px;
    width: 100%;
    background: $divbgColor;
    ul{
      display: block;
      line-height: 30px;
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      li{
        float: left;
        display: flex;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    #search{
      width: 100%;
      height: 120px;
      background: #ffffff;
      .logosearch{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        z-index: 99;
        justify-content: left;
        align-items: center;
        #logo{
          img{
            margin-right: 80px;
            z-index: 9999;
          }
        }
      }
    }
  }
  .bodycontent{
    background: #FFFFFF;
    border-top: 4px #C6824E solid;
    width: 1200px;
    margin: 0 auto;
    margin-top: 10px;
    // height: 500px;
    .detail_title{
      height: 69px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .detail_title_p{
        font-size: 14px;
        color: #333333;
        .title_p_span{
          font-size: 18px;
          color: #C6824E;
          font-weight: bold;
        }
      }
      .detail_title_span{
        .detail_title_button{
          margin-left: 20px;
          width: 100px;
          height: 30px;
          background: $allbgColor;
          border: 1px solid #EAEAEA;
          border-radius: 4px;
          color: #666666;
        }
      }
    }
    .detail_msg{
      padding: 0 20px;
      display: flex;
      .msg_left{
        width: 410px;
        margin-right: 10px;
        background: #FFEEE0;
        .pay_div{
          // height: 100%;
          display: flex;
          flex-flow: column;
          flex: 1;
          padding: 20px;
          .wenzi{
            margin-top: 40px;
            p{
              text-align: center;
              color: #333333;
              span{
                font-size: 18px;
                color: #C6824E;
                font-weight: bold;
              }
            }
            .pay_div_p1{
              line-height: 25px;
            }
            .pay_div_p2{
              color: #999999;
            }
          }
          .pay_div_btn{
            margin-top: 90px;
            display: flex;
            justify-content: space-around;
            button{
              width: 100px;
              height: 30px;
              background: #FFEEE0;
              border: 1px solid #C6824E;
              border-radius: 4px;
              font-size: 14px;
              color: #C6824E;
            }
            .pay_btn2{
              background: #C6824E;
              color: #ffffff;
            }
          }
          a{
            line-height: 30px;
            text-align: center;
            color: #999999;
          }
          .wuliu_title{
            // padding: 5px 0;
            line-height: 30px;
            border-bottom: 1px solid #E5B18A;
            h3{
              color: #333333;
              font-size: 18px;
            }
            .row_left{
              flex: 1;
              .msg_title{
                display: inline-block;
                color: #E5B18A;
                width: 80px;
                max-width: 100px;
                text-align-last: justify;
              }
              .msg_cont{
                color: #C6824E;
              }
            }
          }
          .wuliu_body{
            margin: 10px 0;
            h3{
              color: #333333;
              font-size: 18px;
            }
            .el-timeline{
              margin-top: 10px;
            }
          }
        }
      }
      .msg_right{
        flex: 1;
        padding: 27px 20px;
        background: #F7F7F7;
        border: 1px solid #F7F7F7;
        h3{
          margin-bottom: 35px;
          font-size: 18px;
        }
        .show_msg{
          display: flex;
          flex-flow: column;
          .rowdiv{
            display: flex;
            line-height: 30px;
            .row_left{
              flex: 1;
              .msg_title{
                display: inline-block;
                color: #999999;
                width: 100px;
                max-width: 100px;
                text-align-last: justify;
              }
              .msg_cont{
                color: #333333;
                .more{
                  color: #C6824E;
                  cursor: pointer;
                }
              }
            }
            .row_right{
               flex: 1;
              .msg_title{
                display: inline-block;
                color: #999999;
                width: 100px;
                text-align-last: justify;
              }
              .msg_cont{
                color: #333333;
              }
            }
          }
          .right_row5{
            margin-top: 10px;
            .row_left{
              display: flex;
              .msg_title{
                width: 50px !important;
              }
              .msg_cont{
                flex: 1;
                height: 20px;
                .el-textarea{
                  .el-textarea__inner{
                    background: #F7F7F7;
                  }
                }
              }
            }
          }
        }
      }
    }
    .detail_msg_yh{
      padding:20px;
      display: flex;
      // min-height: 800px;
      .msg_left{
        flex: 1;
        margin-right: 10px;
        background: #FFEEE0;
        .pay_div{
          // height: 100%;
          display: flex;
          flex-flow: column;
          flex: 1;
          padding: 20px;
          a{
            line-height: 30px;
            text-align: center;
            color: #999999;
          }
          .wuliu_title{
            // padding: 5px 0;
            line-height: 30px;
            h3{
              color: #333333;
              font-size: 18px;
              margin-bottom: 35px;
            }
            .row_left{
              flex: 1;
              .msg_title{
                display: inline-block;
                color: #E5B18A;
                width: 100px;
                max-width: 120px;
                text-align-last: justify;
              }
              .msg_cont{
                color: #C6824E;
              }
            }
          }
        }
      }
      .msg_right{
        box-sizing: border-box;
        flex: 1;
        padding: 27px 20px;
        background: #F7F7F7;
        border: 1px solid #F7F7F7;
        h3{
          margin-bottom: 35px;
          font-size: 18px;
        }
        .show_msg{
          display: flex;
          flex-flow: column;
          .rowdiv{
            display: flex;
            line-height: 30px;
            .row_left{
              flex: 1;
              .msg_title{
                display: inline-block;
                color: #999999;
                width: 100px;
                max-width: 100px;
                text-align-last: justify;
              }
              .msg_cont{
                color: #333333;
                .more{
                  color: #C6824E;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
    .datail_seller,.datail_order{
      display: block;
      margin: 0 20px;
      margin-top: 10px;
      // height: 50px;
      background: #F7F7F7;
      .datail_seller_p,.datail_order_p{
        margin: 0 20px;
        line-height: 50px;
        font-weight: bold;
        .el-tag{
          margin: 0 20px;
        }
      }
      .datail_seller_constent{
        // display: block;
        display: flex;
        flex-flow: wrap;
        padding: 0 20px;
        line-height: 60px;
        // height: 60px;
        p{
          float: left;
          color: #999999;
          margin-right: 40px;
          span{
            color: #333333;
          }
        }
      }
    }
    .datail_order{
      .datail_order_table{
        // margin-top: 10px;
        .order_table{
          width: 100%;
          tr{
            display: flex;
            th{
              height: 30px;
              line-height: 30px;
              background: #F7F7F7;
              border: 1px solid #EAEAEA;
              text-align: left;
              box-sizing: border-box;
              padding: 0 20px;
            }
            th:not(:first-child){
              flex: 1;
            }
            th:first-child{
              min-width: 270px;
            }
            .order_body_ul{
              flex: 1;
              display: flex;
              .order_body_li{
                height: 120px;
                box-sizing: border-box;
                padding: 20px;
                border: 1px solid #EAEAEA;
                .money_span,p{
                  color: #333333;
                }
              }
              .order_body_li:not(:first-child){
                flex: 1;
              }
              .order_body_li:first-child{
                min-width: 270px;
                max-width: 270px;
                .namebody{
                  display: flex;
                  img{
                    height: 80px;
                    width: 80px;
                  }
                  .name_time{
                    margin-left: 10px;
                    p{
                      // width: 135px;
                      text-overflow: ellipsis;
                      // white-space: nowrap;
                      overflow: hidden;
                      color: #333333;
                      line-height: 16px;
                      // color: red;
                      // margin-bottom: 15px;
                    }
                  }
                }
              }
            }
          }
          .order_table_title{
            th{
              background: #C6824E;
              color: #ffffff;
            }
          }
        }
      }
    }
    .datail_order_yh{
      // min-height: 500px;
      padding-bottom: 100px;
    }
  }
  .bodyfoot{
    background: #FFFFFF;
    width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    p{
      text-align: left;
      margin: 10px 0;
      span{
        font-size: 24px;
        font-weight: bold;
        color: #C6824E;
      }
    }
    .ft{
      color: #999999;
      span{
        color: #333333;
        font-size: 24px;
      }
    }
  }
  .bfoot{
    width: 1200px;
    height: 70px;
    background: #280E01;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    p{
      text-align: right;
      line-height: 70px;
      color: #FFFEFE;
      font-weight: bold;
      font-size: 24px;
    }
  }
}
.divfooter{
  background: #280E01;
}
</style>
